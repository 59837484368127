//my styles

.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: percentage($containerPaddingLeft / 100);
    padding-right:percentage($containerPaddingRight / 100);
    width:100%;
    max-width:$containerMaxWidth;
    @media (min-width:992px) and (max-width:1199px) {
        padding-left: percentage($containerPaddingLeftSMMD / 100);
        padding-right:percentage($containerPaddingRightSMMD / 100);
    }
    @media (min-width:768px) and (max-width:992px) {
        padding-left: percentage($containerPaddingLeftSM / 100);
        padding-right:percentage($containerPaddingRightSM / 100);
    }
    @media #{$media-xs}{
        padding-left: percentage($containerPaddingLeftXS / 100);
        padding-right:percentage($containerPaddingRightXS / 100);
    }
}
.container-left{
    padding-left: $containerPaddingLeft+vw;
    width:100%;
    max-width:900px;
    margin:0 auto;
    @media (min-width:992px) and (max-width:1199px) {
        padding-left: $containerPaddingLeftSMMD+vw;
    }
}
.container-right{
    padding-right: $containerPaddingRight+vw;
    width:100%;
    @media (min-width:992px) and (max-width:1199px) {
        padding-right: $containerPaddingRightSMMD+vw;
    }
}
._g-out-container{
    margin-left:-$containerPaddingLeft+vw;
    margin-right:-$containerPaddingRight+vw;
    @media (min-width: 1750px) {
        margin: 0 0 0 -50vw;
        left: 49.5%;
        width: 100vw;
        position:relative;
    }
    @media (min-width:992px) and (max-width:1199px) {
        margin-left:-$containerPaddingLeftSMMD+vw;
        margin-right:-$containerPaddingRightSMMD+vw;
    }
    @media #{$media-xs}{
        margin-left:-$containerPaddingLeftXS+vw;
        margin-right:-$containerPaddingRightXS+vw;
    }
}
.fade {
    opacity: 0;
    -webkit-transition: opacity .15s linear;
    -o-transition: opacity .15s linear;
    transition: opacity .15s linear;
}
.fade.in {
    opacity: 1;
}
.collapse {
    display: none;
    visibility: hidden;
}
.collapse.in,
.collapse.show{
    display: block;
    visibility: visible;
}
tr.collapse.in {
    display: table-row;
}
tbody.collapse.in {
    display: table-row-group;
}
.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition-timing-function: ease;
    transition-duration: .35s;
    transition-property: height, visibility;
}
.tab-content {
    > .tab-pane {
        display: none;
    }
    > .active {
        display: block;
        opacity:1;
    }
}




//modals
.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    display: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;
}
.modal.fade .modal-dialog {
    transition:         transform .3s ease-out;
    transform: translate(0, -25%);
}
.modal.in .modal-dialog {
    transform: translate(0, 0);
}
.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}
.modal-dialog {
    position: relative;
    width: auto;
    margin: 10px;
}
.modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
}
.modal-backdrop {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background-color: #000;
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}



* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
html {
    font-size: 10px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
    line-height: 1.42857143;
}
input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
figure {
    margin: 0;
}
img {
    vertical-align: middle;
}
.img-responsive,
.thumbnail > img,
.thumbnail a > img,
.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
    display: block;
    max-width: 100%;
    height: auto;
}
.img-circle {
    border-radius: 50%;
}
hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
}
.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}
.text-center {
    text-align: center;
}
.text-justify {
    text-align: justify;
}
.text-nowrap {
    white-space: nowrap;
}
.text-uppercase {
    text-transform: uppercase;
}
.text-capitalize {
    text-transform: capitalize;
}
.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after{
    display: table;
    content: " ";
}
.clearfix:after,
.container:after,
.container-fluid:after,
.row:after{
    clear: both;
}
.center-block {
    display: block;
    margin-right: auto;
    margin-left: auto;
}
.pull-right {
    float: right !important;
}
.pull-left {
    float: left !important;
}
.hide {
    display: none !important;
}
.show {
    display: block !important;
}
.invisible {
    visibility: hidden;
}
.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}
.hidden {
    display: none !important;
    visibility: hidden !important;
}
.affix {
    position: fixed;
}
@-ms-viewport {
    width: device-width;
}
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
    display: none !important;
}



@media (max-width: ($sm - 1)) {
    .visible-xs {
        display: block !important;
    }
}
@media (min-width: $sm) and (max-width: ($md - 1)) {
    .visible-sm {
        display: block !important;
    }
}
@media (min-width: $md) and (max-width: ($lg - 1)) {
    .visible-md {
        display: block !important;
    }
}
@media (min-width: $lg) {
    .visible-lg {
        display: block !important;
    }
}


@media (max-width: ($sm - 1)) {
    .hidden-xs {
        display: none !important;
    }
}
@media (min-width: $sm) and (max-width: ($md - 1)) {
    .hidden-sm {
        display: none !important;
    }
}
@media (min-width: $md) and (max-width: ($lg - 1)) {
    .hidden-md {
        display: none !important;
    }
}
@media (min-width: $lg) {
    .hidden-lg {
        display: none !important;
    }
}



//forms

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}
legend {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
    font-size: 21px;
    line-height: inherit;
    color: #333;
    border: 0;
    border-bottom: 1px solid #e5e5e5;
}
label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: bold;
}
input[type="search"] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
input[type="radio"],
input[type="checkbox"] {
    margin: 4px 0 0;
    margin-top: 1px \9;
    line-height: normal;
}
input[type="file"] {
    display: block;
}
input[type="range"] {
    display: block;
    width: 100%;
}
select[multiple],
select[size] {
    height: auto;
}
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}
output {
    display: block;
    padding-top: 7px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
}
.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}
.form-control:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);
}
.form-control::-moz-placeholder {
    color: #999;
    opacity: 1;
}
.form-control:-ms-input-placeholder {
    color: #999;
}
.form-control::-webkit-input-placeholder {
    color: #999;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    cursor: not-allowed;
    background-color: #eee;
    opacity: 1;
}
textarea.form-control {
    height: auto;
}
input[type="search"] {
    -webkit-appearance: none;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
    input[type="date"],
    input[type="time"],
    input[type="datetime-local"],
    input[type="month"] {
        line-height: 34px;
    }
}
.form-group {
    margin-bottom: 15px;
}
.radio,
.checkbox {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
}
.radio label,
.checkbox label {
    min-height: 20px;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
}
.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
    position: absolute;
    margin-top: 4px \9;
    margin-left: -20px;
}
input[type="radio"][disabled],
input[type="checkbox"][disabled],
input[type="radio"].disabled,
input[type="checkbox"].disabled,
fieldset[disabled] input[type="radio"],
fieldset[disabled] input[type="checkbox"] {
    cursor: not-allowed;
}
.radio-inline.disabled,
.checkbox-inline.disabled,
fieldset[disabled] .radio-inline,
fieldset[disabled] .checkbox-inline {
    cursor: not-allowed;
}
.radio.disabled label,
.checkbox.disabled label,
fieldset[disabled] .radio label,
fieldset[disabled] .checkbox label {
    cursor: not-allowed;
}
