// -------------   Шрифт -------------------
$mainFont                 :'Roboto', Helvetica, Arial, sans-serif;
$bodyBackground           :#fff;
$mainColor                :#000;
$redColor                 :#E10010;
$customScroll             :true;
// -------------   grid --------------------
$sm                       :768px;
$md                       :992px;
$lg                       :1200px;
$xlg                      :1400px;
$xxlg                     :1650px;
// -------------  контейнера ---------------
$containerMaxWidth         :2500px;
$containerPaddingRight     :5;
$containerPaddingLeft      :5;
$containerPaddingRightSMMD :3;
$containerPaddingLeftSMMD  :3;
$containerPaddingRightSM   :4;
$containerPaddingLeftSM    :4;
$containerPaddingRightXS   :7;
$containerPaddingLeftXS    :7;
